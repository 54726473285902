// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".HeaderContentSubView_reset-list_2ck5N{margin:0;padding:0;list-style:none;display:block}.HeaderContentSubView_uppercase-sm_3TZsb,.HeaderContentSubView_viewTitle_1a38S{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.HeaderContentSubView_headerRow_mtSB_{display:flex;align-items:center;height:100%;min-height:54px}.HeaderContentSubView_right_1BQcD{margin-left:auto}.HeaderContentSubView_titleGroup_2a7Sa{display:flex;justify-content:flex-end;align-items:center}.HeaderContentSubView_viewTitle_1a38S{text-align:right;letter-spacing:.03667em;color:#003168}.HeaderContentSubView_flagIcon_3jGml{margin-left:10px}.HeaderContentSubView_flagIcon_3jGml svg{display:block;width:26px;height:22px}", ""]);
// Exports
exports.locals = {
	"reset-list": "HeaderContentSubView_reset-list_2ck5N",
	"uppercase-sm": "HeaderContentSubView_uppercase-sm_3TZsb",
	"viewTitle": "HeaderContentSubView_viewTitle_1a38S",
	"headerRow": "HeaderContentSubView_headerRow_mtSB_",
	"right": "HeaderContentSubView_right_1BQcD",
	"titleGroup": "HeaderContentSubView_titleGroup_2a7Sa",
	"flagIcon": "HeaderContentSubView_flagIcon_3jGml"
};
module.exports = exports;
