// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AppFooter_reset-list_jN4y3{margin:0;padding:0;list-style:none;display:block}.AppFooter_uppercase-sm_275fT{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.AppFooter_footer_j4JeT{border-top:1px solid #d9d9d9;padding:12px 0;margin:0 40px;font-size:10px}.AppFooter_text_11PHW{color:#9ca6b1;text-align:center;line-height:1.4}", ""]);
// Exports
exports.locals = {
	"reset-list": "AppFooter_reset-list_jN4y3",
	"uppercase-sm": "AppFooter_uppercase-sm_275fT",
	"footer": "AppFooter_footer_j4JeT",
	"text": "AppFooter_text_11PHW"
};
module.exports = exports;
