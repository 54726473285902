<script>
import countryFlags from '../countryFlags';

export default {
  name: 'CountrySelectOption',
  props: {
    option: {
      type: Object,
      required: true,
    },
  },
  computed: {
    dynamicIcon() {
      return countryFlags[`${this.option.countryCode}.svg`]
    }
  },
}
</script>

<template>
  <div :class="$style.option">
    <component :is="dynamicIcon" />
    <div :class="$style.optionText">
      {{ option.label }}
    </div>
  </div>
</template>

<style lang="scss" module>
.option {
  display: flex;
  width: 100%;
}

.optionText {
  font-size: $font-size-base;
  color: $select-text;
  letter-spacing: 0.009375em;
  margin-left: $spacer-4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.option svg {
  display: block;
  width: 26px;
  height: 22px;
}
</style>
