// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FormText_reset-list_17tlU{margin:0;padding:0;list-style:none;display:block}.FormText_uppercase-sm_V8bLK{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.FormText_textSection_13yk8{position:relative;text-align:center}.FormText_textSectionText_2t2I3{display:inline-block;vertical-align:top;color:#3a3a3a;font-size:14px}", ""]);
// Exports
exports.locals = {
	"reset-list": "FormText_reset-list_17tlU",
	"uppercase-sm": "FormText_uppercase-sm_V8bLK",
	"textSection": "FormText_textSection_13yk8",
	"textSectionText": "FormText_textSectionText_2t2I3"
};
module.exports = exports;
