<script>
import AppForm from '../components/AppForm';
import getFieldErrors from '../getFieldErrors';
import ChallengeLoader from '../components/ChallengeLoader';

export default {
  name: 'SignatureMobileId',
  components: {
    AppForm,
    ChallengeLoader,
  },
  data() {
    return {
      fieldErrors: {},
      challenge: undefined,
      signingProcess: {},
    }
  },
  computed: {
    inputValues() {
      return this.$root.$options.storeGetters.inputValues();
    },
    callingCode() {
      return this.$root.$options.storeGetters.callingCode();
    },
    additionalMethodConfig() {
      return this.$root.$options.storeGetters.additionalMethodConfig();
    },
    schema() {
      return [
        {
          type: 'tel',
          name: 'phone',
          label: this.$t('phone-number'),
          validation: ['required'],
          callingCode: this.callingCode,
        },
        {
          type: 'text',
          name: 'idcode',
          label: this.$t('idcode'),
          validation: ['required'],
        },
        {
          type: 'submit',
          label: this.$t('Sign')
        }
      ]
    },
    formValue: {
      get() {
        return {
          phone: this.inputValues.phone,
          idcode:  this.inputValues.idcode,
        }
      },
      set(newValues){
        this.updateInputValues(newValues);
      }
    },
  },
  methods: {
    updateInputValues(newValues) {
      this.$root.$options.storeActions.updateInputValues(newValues);
    },
    loadingStart() {
      this.$root.$options.storeActions.loadingStart();
    },
    loadingEnd() {
      this.$root.$options.storeActions.loadingEnd();
    },
    addFlashMessage(message) {
      this.$root.$options.storeActions.addFlashMessage(message);
    },
    clearFlashMessages() {
      this.$root.$options.storeActions.clearFlashMessages();
    },
    startSignatureProcessing() {
      this.$root.$options.storeActions.startSignatureProcessing();
    },
    endSignatureProcessing() {
      this.$root.$options.storeActions.endSignatureProcessing();
    },
    clearErrors() {
      this.fieldErrors = {};
    },
    sign() {
      this.loadingStart();
      this.clearFlashMessages();
      this.clearErrors();

      const dataToSubmit = {
        ...this.formValue,
      };

      dataToSubmit.phone = this.callingCode + dataToSubmit.phone;
      const additionalMethodConfig = this.additionalMethodConfig || {};
      this.signingProcess = this.$root.$options.eidEasyClient.signature.mobileIdSignature.start({
        ...dataToSubmit,
        started: (result) => {
          if (result.data && result.data.challenge) {
            this.challenge = result.data.challenge;
            this.loadingEnd();
            this.startSignatureProcessing();
          }
        },
        fail: (error) => {
          if (!error.isCancel) {
            this.addFlashMessage(error);
          }
          this.fieldErrors = getFieldErrors(error);
          this.$root.$options.eidEasyOnFail(error);
        },
        success: (result) => {
          this.$root.$options.eidEasyOnSuccess(result);
        },
        finished: () => {
          this.challenge = undefined;
          this.loadingEnd();
          this.endSignatureProcessing();
        },
        ...additionalMethodConfig,
      });
    }
  },
}
</script>

<template>
  <div :class="$style.formContainer">
    <div
      v-if="challenge"
      :class="$style.challenge"
    >
      <ChallengeLoader :challenge="challenge" />
    </div>
    <div v-else>
      <AppForm
        id="mobileIdSignatureForm"
        v-model="formValue"
        :schema="schema"
        :on-submit="sign"
        :errors="fieldErrors"
      />
    </div>
  </div>
</template>

<style lang="scss" module>
.formContainer {
  max-width: $form-container-width;
  margin: 0 auto;
}
</style>
