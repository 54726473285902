<script>
import countryFlags from '../countryFlags';
import BackButton from './BackButton';
import viewNames from '../views/viewNames';
import countries from '../i18n/countries';

export default {
  name: 'HeaderContentSubView',
  components: {
    BackButton,
  },
  data() {
    return {
      viewNames,
    }
  },
  computed: {
    countryCode() {
      return this.$root.$options.storeGetters.countryCode();
    },
    currentViewTitleKey() {
      return this.$root.$options.storeGetters.currentViewTitleKey();
    },
    currentFlagIcon() {
      return countryFlags[`${this.countryCode}.svg`]
    },
    currentCountryName() {
      return countries.getName(this.countryCode, this.$i18n.locale, {select: "official"});
    },
    userActionsEnabled() {
      return this.$root.$options.storeGetters.userActionsEnabled();
    }
  },
  methods: {
    changeView(viewName) {
      return this.$root.$options.storeActions.changeView(viewName);
    }
  },
}
</script>

<template>
  <div :class="$style.headerRow">
    <div :class="$style.left">
      <BackButton
        :on-click="() => changeView(viewNames.MethodSelection)"
        :disabled="!userActionsEnabled"
      />
    </div>

    <div :class="$style.right">
      <div :class="$style.titleGroup">
        <div :class="$style.viewTitle">
          {{ $t(currentViewTitleKey) }}
        </div>
        <div
          :class="$style.flagIcon"
          :aria-label="$t('country') + ': ' + currentCountryName"
        >
          <component :is="currentFlagIcon" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.headerRow {
  display: flex;
  align-items: center;
  height: 100%;
  min-height: 54px;
}

.right {
  margin-left: auto;
}

.titleGroup {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.viewTitle {
  @extend .uppercase-sm;
  text-align: right;
  letter-spacing: 0.03667em;
  color: $primary;
}

.flagIcon {
  margin-left: 10px;
}

.flagIcon svg {
  display: block;
  width: 26px;
  height: 22px;
}
</style>
