// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".GoogleButton_reset-list_gS85S{margin:0;padding:0;list-style:none;display:block}.GoogleButton_uppercase-sm_lleoJ{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.GoogleButton_icon_15sMa{display:block;width:22px;height:auto;margin-right:10px;fill:#ce161e}", ""]);
// Exports
exports.locals = {
	"reset-list": "GoogleButton_reset-list_gS85S",
	"uppercase-sm": "GoogleButton_uppercase-sm_lleoJ",
	"icon": "GoogleButton_icon_15sMa"
};
module.exports = exports;
