// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AppInput_reset-list_1dsTS{margin:0;padding:0;list-style:none;display:block}.AppInput_uppercase-sm_2M88P{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.AppInput_input_2y67O{color:#3a3a3a;font-size:16px;width:100%;display:block;border-radius:4px;-webkit-appearance:none;outline:none;border:1px solid rgba(0,49,104,.2);line-height:1.2;padding:16px 16px;letter-spacing:.0275em}.AppInput_input_2y67O:focus{border-color:#003168;box-shadow:0 0 0 2px rgba(0,49,104,.2)}.AppInput_error_32T_l,.AppInput_error_32T_l:focus{border-color:#dc3545}.AppInput_error_32T_l:focus{box-shadow:0 0 0 2px rgba(220,53,69,.2)}.AppInput_hasPrefix_2F8MW{display:flex;align-items:center}.AppInput_hasPrefix_prefix_2fsXa{color:#3a3a3a;font-size:16px;display:block;border-radius:4px;-webkit-appearance:none;outline:none;border:1px solid rgba(0,49,104,.2);line-height:1.2;padding:16px 8px;letter-spacing:.0275em;margin-right:5px}", ""]);
// Exports
exports.locals = {
	"reset-list": "AppInput_reset-list_1dsTS",
	"uppercase-sm": "AppInput_uppercase-sm_2M88P",
	"input": "AppInput_input_2y67O",
	"error": "AppInput_error_32T_l",
	"hasPrefix": "AppInput_hasPrefix_2F8MW",
	"hasPrefix_prefix": "AppInput_hasPrefix_prefix_2fsXa"
};
module.exports = exports;
