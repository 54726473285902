<script>
import MethodButtonBase from './MethodButtonBase';
import SingMeLogo from '../../assets/svg/signme-logo-color.svg';
export default {
  name: 'DTrustSignMeButton',
  components: {
    MethodButtonBase,
    SingMeLogo,
  },
}
</script>

<template>
  <MethodButtonBase>
    <template v-slot:slot1>
      <SingMeLogo :class="$style.icon" />
    </template>
  </MethodButtonBase>
</template>


<style lang="scss" module>
.icon {
  display: block;
  width: 102px;
  height: auto;
  margin-top: -5px;
}
</style>
