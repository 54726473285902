// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SignatureDTrustSignMe_reset-list_2HpSE{margin:0;padding:0;list-style:none;display:block}.SignatureDTrustSignMe_uppercase-sm_3sWsN{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.SignatureDTrustSignMe_formContainer_1eEFC{max-width:263px;margin:0 auto}.SignatureDTrustSignMe_label_3J-T3{color:#9ca6b1;margin-top:24px}.SignatureDTrustSignMe_highlight_aw3Wz{font-weight:700;font-size:20px}", ""]);
// Exports
exports.locals = {
	"reset-list": "SignatureDTrustSignMe_reset-list_2HpSE",
	"uppercase-sm": "SignatureDTrustSignMe_uppercase-sm_3sWsN",
	"formContainer": "SignatureDTrustSignMe_formContainer_1eEFC",
	"label": "SignatureDTrustSignMe_label_3J-T3",
	"highlight": "SignatureDTrustSignMe_highlight_aw3Wz"
};
module.exports = exports;
