<script>
import AppButton from '../components/AppButton';

export default {
  name: 'SignatureFtn',
  components: {
    AppButton,
  },
  data() {
    return {
      signingProcess: {},
    }
  },
  computed: {
    additionalMethodConfig() {
      return this.$root.$options.storeGetters.additionalMethodConfig();
    },
  },
  methods: {
    loadingStart() {
      this.$root.$options.storeActions.loadingStart();
    },
    loadingEnd() {
      this.$root.$options.storeActions.loadingEnd();
    },
    addFlashMessage(message) {
      this.$root.$options.storeActions.addFlashMessage(message);
    },
    clearFlashMessages() {
      this.$root.$options.storeActions.clearFlashMessages();
    },
    sign() {
      this.loadingStart();
      this.clearFlashMessages();
      const additionalMethodConfig = this.additionalMethodConfig || {};
      this.signingProcess = this.$root.$options.eidEasyClient.signature.ftnSignature.start({
        fail: (error) => {
          if (!error.isCancel) {
            this.addFlashMessage(error);
          }
          this.$root.$options.eidEasyOnFail(error);
        },
        success: (result) => {
          this.$root.$options.eidEasyOnSuccess(result);
        },
        finished: () => {
          this.loadingEnd();
        },
        ...additionalMethodConfig,
      });
    }
  },
}
</script>

<template>
  <div>
    <div :class="$style.note">
      {{ $t('pre-sign-confirmation', {method: $t('finnish-online-bank')}) }}
    </div>
    <div :class="$style.formContainer">
      <AppButton :on-click="sign">
        {{ $t('Sign') }}
      </AppButton>
    </div>
  </div>
</template>

<style lang="scss" module>
.formContainer {
  max-width: $form-container-width;
  margin: 0 auto;
}

.note {
  margin-bottom: $spacer-5;
  text-align: center;
}
</style>
