// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SmartIdAuth_reset-list_HPBy3{margin:0;padding:0;list-style:none;display:block}.SmartIdAuth_uppercase-sm_1P3mW{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.SmartIdAuth_formContainer_yaU9W{max-width:263px;margin:0 auto}", ""]);
// Exports
exports.locals = {
	"reset-list": "SmartIdAuth_reset-list_HPBy3",
	"uppercase-sm": "SmartIdAuth_uppercase-sm_1P3mW",
	"formContainer": "SmartIdAuth_formContainer_yaU9W"
};
module.exports = exports;
