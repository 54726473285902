// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AppForm_reset-list_1Fz7z{margin:0;padding:0;list-style:none;display:block}.AppForm_uppercase-sm_prD3q{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.AppForm_formElement_1oDl9{display:block}.AppForm_formElement_1oDl9+.AppForm_formElement_1oDl9{margin-top:24px}.AppForm_formElement_1oDl9+.AppForm_formElementSpacer_1taY5{margin-top:12px;margin-bottom:-20px}", ""]);
// Exports
exports.locals = {
	"reset-list": "AppForm_reset-list_1Fz7z",
	"uppercase-sm": "AppForm_uppercase-sm_prD3q",
	"formElement": "AppForm_formElement_1oDl9",
	"formElementSpacer": "AppForm_formElementSpacer_1taY5"
};
module.exports = exports;
