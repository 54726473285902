// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SignatureEvrotrust_reset-list_3pmVr{margin:0;padding:0;list-style:none;display:block}.SignatureEvrotrust_uppercase-sm_3FbVw{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.SignatureEvrotrust_formContainer_Uo6cB{max-width:263px;margin:0 auto}.SignatureEvrotrust_label_2R-NQ{color:#9ca6b1;margin-top:24px}.SignatureEvrotrust_highlight_eQMma{font-weight:700;font-size:20px}", ""]);
// Exports
exports.locals = {
	"reset-list": "SignatureEvrotrust_reset-list_3pmVr",
	"uppercase-sm": "SignatureEvrotrust_uppercase-sm_3FbVw",
	"formContainer": "SignatureEvrotrust_formContainer_Uo6cB",
	"label": "SignatureEvrotrust_label_2R-NQ",
	"highlight": "SignatureEvrotrust_highlight_eQMma"
};
module.exports = exports;
