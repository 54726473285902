<script>
export default {
  name: 'FormSectionSpacer',
  props: {
    text: String,
  },
}
</script>

<template>
  <div :class="$style.spacer">
    <div :class="$style.spacerText">
      {{ text }}
    </div>
  </div>
</template>

<style lang="scss" module>
  .spacer {
    position: relative;
    text-align: center;
  }

  .spacerText {
    display: inline-block;
    vertical-align: top;
    color: $gray-700;
  }
</style>
