// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SignatureFtn_reset-list_2H5_g{margin:0;padding:0;list-style:none;display:block}.SignatureFtn_uppercase-sm_zRjwU{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.SignatureFtn_formContainer_2vlwq{max-width:263px;margin:0 auto}.SignatureFtn_note_1ZHfT{margin-bottom:20px;text-align:center}", ""]);
// Exports
exports.locals = {
	"reset-list": "SignatureFtn_reset-list_2H5_g",
	"uppercase-sm": "SignatureFtn_uppercase-sm_zRjwU",
	"formContainer": "SignatureFtn_formContainer_2vlwq",
	"note": "SignatureFtn_note_1ZHfT"
};
module.exports = exports;
