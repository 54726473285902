<script>
import MethodButton from '../components/methodButtons/MethodButton';

export default {
  name: 'MethodSelection',
  components: {
    MethodButton,
  },
  computed: {
    identificationMethodsInCurrentCountry() {
      return this.$root.$options.storeGetters.identificationMethodsInCurrentCountry();
    },
    signingMethodsInCurrentCountry() {
      return this.$root.$options.storeGetters.signingMethodsInCurrentCountry();
    },
    isLoading() {
      return this.$root.$options.storeGetters.isLoading();
    },
    currentMethod() {
      return this.$root.$options.storeGetters.currentMethod();
    },
    additionalMethodConfig() {
      return this.$root.$options.storeGetters.additionalMethodConfig();
    },
    isThinLayout() {
      return this.identificationMethodsInCurrentCountry.length % 2 !== 0 ||
          this.signingMethodsInCurrentCountry.length % 2 !== 0;
    },
    methodsByActionType() {
      return this.$root.$options.eidEasyMethodsConfig.methodsByActionType;
    }
  },
  watch: {
    currentMethod: {
      handler(newMethod) {
        if (!newMethod) {
          return;
        }
        const method = this.methodsByActionType[newMethod.actionType];
        // if a method doesn't have a viewname then it means that this method
        // has no sub views and doesn't need any additional user input and can therefore be started immediately
        if (!method.viewName) {
          this.loadingStart();
          this.clearFlashMessages();
          this.startMethod(method.methodType, method.handlingModule);
        }
      },
      immediate: true,
    },
  },
  methods: {
    async selectMethod(actionType) {
      this.loadingStart();
      const beforeSelectionCallbackResult = await this.$root.$options.eidEasyBeforeMethodSelection({actionType});
      this.loadingEnd();
      if (beforeSelectionCallbackResult === false) {
        return;
      }
      if (beforeSelectionCallbackResult && beforeSelectionCallbackResult.additionalMethodConfig) {
        this.changeAdditionalMethodConfig(beforeSelectionCallbackResult.additionalMethodConfig);
      }
      return this.$root.$options.storeActions.selectMethod(actionType);
    },
    loadingStart() {
      return this.$root.$options.storeActions.loadingStart();
    },
    clearFlashMessages() {
      return this.$root.$options.storeActions.clearFlashMessages();
    },
    loadingEnd() {
      return this.$root.$options.storeActions.loadingEnd();
    },
    addFlashMessage(message) {
      return this.$root.$options.storeActions.addFlashMessage(message);
    },
    changeAdditionalMethodConfig(config) {
      return this.$root.$options.storeActions.changeAdditionalMethodConfig(config);
    },
    failHandler(result) {
      this.addFlashMessage(result);
      this.loadingEnd();
      this.$root.$options.eidEasyOnFail(result);
    },
    successHandler(result) {
      this.$root.$options.eidEasyOnSuccess(result);
    },
    finishedHandler() {
      this.loadingEnd();
    },
    startMethod(methodType, moduleName) {
      const additionalMethodConfig = this.additionalMethodConfig || {};
      this.$root.$options.eidEasyClient[methodType][moduleName].start({
        iframeHolder: this.$refs.iframeHolder,
        fail: this.failHandler,
        success: this.successHandler,
        finished: this.finishedHandler,
        ...additionalMethodConfig,
      });
    },
  },
}
</script>

<template>
  <div
    :class="[$style.methodSelection, {[$style.methodSelectionThin]: isThinLayout}]"
  >
    <div :class="$style.row">
      <div
        v-for="method in identificationMethodsInCurrentCountry"
        :key="method.actionType"
        :class="$style.unit"
      >
        <MethodButton
          :button-name="method.buttonName"
          :disabled="isLoading"
          :on-click="() => selectMethod(method.actionType)"
          :aria-label="$t('login-method') + ' ' + $t(method.translationKeys.title)"
        />
      </div>
    </div>
    <div :class="$style.row">
      <div
        v-for="method in signingMethodsInCurrentCountry"
        :key="method.actionType"
        :class="$style.unit"
      >
        <MethodButton
          :button-name="method.buttonName"
          :disabled="isLoading"
          :on-click="() => selectMethod(method.actionType)"
          :aria-label="$t('signature-method') + ' ' + $t(method.translationKeys.title)"
        />
      </div>
    </div>
    <div ref="iframeHolder" />
  </div>
</template>

<style lang="scss" module>
.methodSelection {
  max-width: 510px;
  margin: 0 auto;
}

.methodSelectionThin {
  max-width: 348px;
  margin: 0 auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$spacer-2;
  margin-right: -$spacer-2;
}

.unit {
  padding: $spacer-2 $spacer-2;
  flex-grow: 1;
  flex-basis: 0;
}

</style>
