// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".MobileIdButton_reset-list_3-2Pt{margin:0;padding:0;list-style:none;display:block}.MobileIdButton_uppercase-sm_27AA6{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.MobileIdButton_icon_28p3J{display:block;width:18px;height:auto;margin-right:8px}", ""]);
// Exports
exports.locals = {
	"reset-list": "MobileIdButton_reset-list_3-2Pt",
	"uppercase-sm": "MobileIdButton_uppercase-sm_27AA6",
	"icon": "MobileIdButton_icon_28p3J"
};
module.exports = exports;
