// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SignatureOtp_reset-list_17PwH{margin:0;padding:0;list-style:none;display:block}.SignatureOtp_uppercase-sm_YV8mK{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.SignatureOtp_formContainer_569q7{max-width:263px;margin:0 auto}.SignatureOtp_line_3EZAw{margin-bottom:12px}.SignatureOtp_mb4_24C5d{margin-bottom:16px}", ""]);
// Exports
exports.locals = {
	"reset-list": "SignatureOtp_reset-list_17PwH",
	"uppercase-sm": "SignatureOtp_uppercase-sm_YV8mK",
	"formContainer": "SignatureOtp_formContainer_569q7",
	"line": "SignatureOtp_line_3EZAw",
	"mb4": "SignatureOtp_mb4_24C5d"
};
module.exports = exports;
