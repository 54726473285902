<script>
import MethodButtonBase from './MethodButtonBase';
import IconSmartId from '../../assets/svg/smart-id.svg';
export default {
  name: 'SmartIdButton',
  components: {
    MethodButtonBase,
    IconSmartId,
  },
}
</script>

<template>
  <MethodButtonBase>
    <template v-slot:slot1>
      <IconSmartId :class="$style.icon" />
    </template>
  </MethodButtonBase>
</template>


<style lang="scss" module>
.icon {
  display: block;
  width: 112px;
  height: auto;
}
</style>
