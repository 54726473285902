// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".HeaderContentCountry_reset-list_1yKOi{margin:0;padding:0;list-style:none;display:block}.HeaderContentCountry_regionTitle_3tKG7,.HeaderContentCountry_uppercase-sm_2rh85{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.HeaderContentCountry_headerRow_BoSao{display:flex;align-items:center;height:100%;flex-wrap:wrap;justify-content:space-between}.HeaderContentCountry_left_2xY8b{padding:4px 8px 4px 0;flex-shrink:0}.HeaderContentCountry_right_22S-a{width:100%;max-width:263px;min-width:0}.HeaderContentCountry_regionTitle_3tKG7{color:#3a3a3a}", ""]);
// Exports
exports.locals = {
	"reset-list": "HeaderContentCountry_reset-list_1yKOi",
	"regionTitle": "HeaderContentCountry_regionTitle_3tKG7",
	"uppercase-sm": "HeaderContentCountry_uppercase-sm_2rh85",
	"headerRow": "HeaderContentCountry_headerRow_BoSao",
	"left": "HeaderContentCountry_left_2xY8b",
	"right": "HeaderContentCountry_right_22S-a"
};
module.exports = exports;
