// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".MitIdButton_reset-list_3t4rE{margin:0;padding:0;list-style:none;display:block}.MitIdButton_uppercase-sm_Cz9Ws{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.MitIdButton_icon_OF38X{display:block;width:78px;height:auto}", ""]);
// Exports
exports.locals = {
	"reset-list": "MitIdButton_reset-list_3t4rE",
	"uppercase-sm": "MitIdButton_uppercase-sm_Cz9Ws",
	"icon": "MitIdButton_icon_OF38X"
};
module.exports = exports;
