const methodButtonNames = {
  IdCardButton: 'IdCardButton',
  IdCardButtonEE: 'IdCardButtonEE',
  IdCardButtonFi: 'IdCardButtonFi',
  IdCardButtonBe: 'IdCardButtonBe',
  SmartIdButton: 'SmartIdButton',
  MobileIdButton: 'MobileIdButton',
  MobileIdButtonEE: 'MobileIdButtonEE',
  EparakstsMobileButton: 'EparakstsMobileButton',
  FrejaEidButton: 'FrejaEidButton',
  ZealIdButton: 'ZealIdButton',
  MojeIdButton: 'MojeIdButton',
  FinnishTrustNetworkButton: 'FinnishTrustNetworkButton',
  AtHandyButton: 'AtHandyButton',
  IdCardButtonLv: 'IdCardButtonLv',
  IdCardButtonPt: 'IdCardButtonPt',
  OtpButton: 'OtpButton',
  GoogleButton: 'GoogleButton',
  EvrotrustButton: 'EvrotrustButton',
  BankIdButton: 'BankIdButton',
  DTrustSignMeButton: 'DTrustSignMeButton',
  MitIdButton: 'MitIdButton',
  ChaveMovelButton: 'ChaveMovelButton',
  NoBankIdButton: 'NoBankIdButton',
  ItsmeButton: 'ItsmeButton',
  CertEuropeUsbButton: 'CertEuropeUsbButton',
  CertSignUsbButton: 'CertSignUsbButton',
};

export default methodButtonNames;
