// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SignatureChaveMovel_reset-list_1UllH{margin:0;padding:0;list-style:none;display:block}.SignatureChaveMovel_uppercase-sm_3VRsW{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.SignatureChaveMovel_formContainer_12uu9{max-width:263px;margin:0 auto}.SignatureChaveMovel_label_rD0VC{color:#9ca6b1;margin-top:24px}.SignatureChaveMovel_highlight_1I15G{font-weight:700;font-size:20px}", ""]);
// Exports
exports.locals = {
	"reset-list": "SignatureChaveMovel_reset-list_1UllH",
	"uppercase-sm": "SignatureChaveMovel_uppercase-sm_3VRsW",
	"formContainer": "SignatureChaveMovel_formContainer_12uu9",
	"label": "SignatureChaveMovel_label_rD0VC",
	"highlight": "SignatureChaveMovel_highlight_1I15G"
};
module.exports = exports;
