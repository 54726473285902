// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BackButton_reset-list_3zPJ0{margin:0;padding:0;list-style:none;display:block}.BackButton_text_NN6rx,.BackButton_uppercase-sm_EOWhn{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.BackButton_button_3Buey{display:flex;align-items:center;text-decoration:none;color:#3a3a3a;transition:.2s}.BackButton_button_3Buey:focus,.BackButton_button_3Buey:hover{text-decoration:none;color:#003168}.BackButton_backIcon_GKFX4{position:relative;margin-right:8px;font-size:16px;border:1px solid currentColor;width:1.5em;height:1.5em;border-radius:1.5em}.BackButton_backCaret_2lhsu{position:absolute;left:.5625em;top:50%;margin-top:-.1875em;display:block;width:.375em;height:.375em;border-left:1px solid currentColor;border-bottom:1px solid currentColor;transform:rotate(45deg)}.BackButton_disabled_whldG{color:#939393}.BackButton_disabled_whldG:focus,.BackButton_disabled_whldG:hover{text-decoration:none;color:#939393}", ""]);
// Exports
exports.locals = {
	"reset-list": "BackButton_reset-list_3zPJ0",
	"text": "BackButton_text_NN6rx",
	"uppercase-sm": "BackButton_uppercase-sm_EOWhn",
	"button": "BackButton_button_3Buey",
	"backIcon": "BackButton_backIcon_GKFX4",
	"backCaret": "BackButton_backCaret_2lhsu",
	"disabled": "BackButton_disabled_whldG"
};
module.exports = exports;
