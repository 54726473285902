// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AppLayout_reset-list_3VDJU{margin:0;padding:0;list-style:none;display:block}.AppLayout_uppercase-sm_2wWui{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.AppLayout_layout_3IIKX{background-color:#fdfcfc;border-radius:6px;border:1px solid #d9d9d9}.AppLayout_main_pW2LQ{position:relative;padding:24px 24px}.AppLayout_fade_3QF-Q-enter-active,.AppLayout_fade_3QF-Q-leave-active{transition:opacity .2s}.AppLayout_fade_3QF-Q-enter,.AppLayout_fade_3QF-Q-leave-to{opacity:0}.AppLayout_eidEasyLink_3EDXL{text-decoration:none;color:currentColor;transition:.2s}.AppLayout_eidEasyLink_3EDXL:focus,.AppLayout_eidEasyLink_3EDXL:hover{text-decoration:none;color:#003168}", ""]);
// Exports
exports.locals = {
	"reset-list": "AppLayout_reset-list_3VDJU",
	"uppercase-sm": "AppLayout_uppercase-sm_2wWui",
	"layout": "AppLayout_layout_3IIKX",
	"main": "AppLayout_main_pW2LQ",
	"fade": "AppLayout_fade_3QF-Q",
	"eidEasyLink": "AppLayout_eidEasyLink_3EDXL"
};
module.exports = exports;
