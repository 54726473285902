<script>
import MethodButtonBase from './MethodButtonBase';
import IconEvro from '../../assets/svg/evrotrust-logo-en.svg';
export default {
  name: 'EvrotrustButton',
  components: {
    MethodButtonBase,
    IconEvro,
  },
}
</script>

<template>
  <MethodButtonBase>
    <template v-slot:slot1>
      <IconEvro :class="$style.icon" />
    </template>
  </MethodButtonBase>
</template>


<style lang="scss" module>
.icon {
  display: block;
  width: 102px;
  height: auto;
  margin-top: -5px;
}
</style>
