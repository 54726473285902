// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".MobileIdAuth_reset-list_-jmEk{margin:0;padding:0;list-style:none;display:block}.MobileIdAuth_uppercase-sm_2H-IM{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.MobileIdAuth_formContainer_3r4pd{max-width:263px;margin:0 auto}", ""]);
// Exports
exports.locals = {
	"reset-list": "MobileIdAuth_reset-list_-jmEk",
	"uppercase-sm": "MobileIdAuth_uppercase-sm_2H-IM",
	"formContainer": "MobileIdAuth_formContainer_3r4pd"
};
module.exports = exports;
