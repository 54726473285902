// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BankIdButton_reset-list_QJUmf{margin:0;padding:0;list-style:none;display:block}.BankIdButton_uppercase-sm_2r9hp{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.BankIdButton_icon_bxPZC{display:block;width:30px;height:auto;margin-right:10px;fill:#193e4f}", ""]);
// Exports
exports.locals = {
	"reset-list": "BankIdButton_reset-list_QJUmf",
	"uppercase-sm": "BankIdButton_uppercase-sm_2r9hp",
	"icon": "BankIdButton_icon_bxPZC"
};
module.exports = exports;
