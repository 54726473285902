<script>
import MethodButtonBase from './MethodButtonBase';
import MitIdLogo from '../../assets/svg/mitid-logo.svg';
export default {
  name: 'MitIdButton',
  components: {
    MethodButtonBase,
    MitIdLogo,
  },
}
</script>

<template>
  <MethodButtonBase>
    <template v-slot:slot1>
      <MitIdLogo :class="$style.icon" />
    </template>
  </MethodButtonBase>
</template>


<style lang="scss" module>
.icon {
  display: block;
  width: 78px;
  height: auto;
}
</style>
