// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ConfirmationStep_reset-list_3aUqo{margin:0;padding:0;list-style:none;display:block}.ConfirmationStep_uppercase-sm_2Hcsz{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.ConfirmationStep_mb4_GElb4{margin-bottom:16px}", ""]);
// Exports
exports.locals = {
	"reset-list": "ConfirmationStep_reset-list_3aUqo",
	"uppercase-sm": "ConfirmationStep_uppercase-sm_2Hcsz",
	"mb4": "ConfirmationStep_mb4_GElb4"
};
module.exports = exports;
