// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NoBankIdButton_reset-list_3-emo{margin:0;padding:0;list-style:none;display:block}.NoBankIdButton_uppercase-sm_e65W5{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.NoBankIdButton_icon_1PvLF{display:block;width:102px;height:auto;margin-top:-1px}", ""]);
// Exports
exports.locals = {
	"reset-list": "NoBankIdButton_reset-list_3-emo",
	"uppercase-sm": "NoBankIdButton_uppercase-sm_e65W5",
	"icon": "NoBankIdButton_icon_1PvLF"
};
module.exports = exports;
