// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".IdentificationFrejaId_reset-list_1ll29{margin:0;padding:0;list-style:none;display:block}.IdentificationFrejaId_uppercase-sm_9UiQo{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.IdentificationFrejaId_formContainer_3V05o{max-width:263px;margin:0 auto}", ""]);
// Exports
exports.locals = {
	"reset-list": "IdentificationFrejaId_reset-list_1ll29",
	"uppercase-sm": "IdentificationFrejaId_uppercase-sm_9UiQo",
	"formContainer": "IdentificationFrejaId_formContainer_3V05o"
};
module.exports = exports;
