// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".EvrotrustButton_reset-list_3MWe7{margin:0;padding:0;list-style:none;display:block}.EvrotrustButton_uppercase-sm_nCrzt{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.EvrotrustButton_icon_3k1o0{display:block;width:102px;height:auto;margin-top:-5px}", ""]);
// Exports
exports.locals = {
	"reset-list": "EvrotrustButton_reset-list_3MWe7",
	"uppercase-sm": "EvrotrustButton_uppercase-sm_nCrzt",
	"icon": "EvrotrustButton_icon_3k1o0"
};
module.exports = exports;
