<script>
import MethodButtonBase from './MethodButtonBase';
import IconGoogle from '../../assets/svg/google.svg';
export default {
  name: 'AtHandyButton',
  components: {
    MethodButtonBase,
    IconGoogle,
  },
}
</script>

<template>
  <MethodButtonBase>
    <template v-slot:slot1>
      <IconGoogle :class="$style.icon" />
    </template>
    <template v-slot:slot2>
      Google
    </template>
  </MethodButtonBase>
</template>


<style lang="scss" module>
.icon {
  display: block;
  width: 22px;
  height: auto;
  margin-right: 10px;
  fill: #ce161e;
}
</style>
