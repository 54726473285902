<script>
import MethodButtonBase from './MethodButtonBase';
import IconFrejaEid from '../../assets/svg/freja-eid.svg';
export default {
  name: 'FrejaEidButton',
  components: {
    MethodButtonBase,
    IconFrejaEid,
  },
}
</script>

<template>
  <MethodButtonBase>
    <template v-slot:slot1>
      <IconFrejaEid :class="$style.icon" />
    </template>
  </MethodButtonBase>
</template>


<style lang="scss" module>
.icon {
  display: block;
  width: 112px;
  height: auto;
}
</style>
