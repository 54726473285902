// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".IdCardButtonEE_reset-list_kE-22{margin:0;padding:0;list-style:none;display:block}.IdCardButtonEE_uppercase-sm_rxoP1{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.IdCardButtonEE_icon_1o5LD{display:block;width:112px;height:auto}", ""]);
// Exports
exports.locals = {
	"reset-list": "IdCardButtonEE_reset-list_kE-22",
	"uppercase-sm": "IdCardButtonEE_uppercase-sm_rxoP1",
	"icon": "IdCardButtonEE_icon_1o5LD"
};
module.exports = exports;
