// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ChallengeLoader_reset-list_1Wqnf{margin:0;padding:0;list-style:none;display:block}.ChallengeLoader_uppercase-sm_3yrzi{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.ChallengeLoader_challenge_238rc{text-align:center;color:#003168}.ChallengeLoader_loader_2agEm{font-size:14px;display:block;margin-left:auto;margin-right:auto;margin-bottom:24px}.ChallengeLoader_challengeTitle_3MmFo{text-transform:uppercase;font-size:12px;font-weight:700;letter-spacing:.03667em}.ChallengeLoader_challengeCode_3tymB{font-size:28px;font-weight:700;letter-spacing:.005357em}.ChallengeLoader_challengeCode_3tymB,.ChallengeLoader_userInstructions_2wXWJ{margin-bottom:16px}", ""]);
// Exports
exports.locals = {
	"reset-list": "ChallengeLoader_reset-list_1Wqnf",
	"uppercase-sm": "ChallengeLoader_uppercase-sm_3yrzi",
	"challenge": "ChallengeLoader_challenge_238rc",
	"loader": "ChallengeLoader_loader_2agEm",
	"challengeTitle": "ChallengeLoader_challengeTitle_3MmFo",
	"challengeCode": "ChallengeLoader_challengeCode_3tymB",
	"userInstructions": "ChallengeLoader_userInstructions_2wXWJ"
};
module.exports = exports;
