// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SignatureSmartId_reset-list_boUBO{margin:0;padding:0;list-style:none;display:block}.SignatureSmartId_uppercase-sm_3zz7A{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.SignatureSmartId_formContainer_3n8sZ{max-width:263px;margin:0 auto}", ""]);
// Exports
exports.locals = {
	"reset-list": "SignatureSmartId_reset-list_boUBO",
	"uppercase-sm": "SignatureSmartId_uppercase-sm_3zz7A",
	"formContainer": "SignatureSmartId_formContainer_3n8sZ"
};
module.exports = exports;
