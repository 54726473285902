// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".IdCardButtonFi_reset-list_1YFL3{margin:0;padding:0;list-style:none;display:block}.IdCardButtonFi_uppercase-sm_1645D{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.IdCardButtonFi_icon_3QCMN{display:block;width:17px;height:auto;margin-right:8px}", ""]);
// Exports
exports.locals = {
	"reset-list": "IdCardButtonFi_reset-list_1YFL3",
	"uppercase-sm": "IdCardButtonFi_uppercase-sm_1645D",
	"icon": "IdCardButtonFi_icon_3QCMN"
};
module.exports = exports;
