<script>
import MethodButtonBase from './MethodButtonBase';
import IconBankId from '../../assets/svg/bankid-logo.svg';
export default {
  name: 'BankIdButton',
  components: {
    MethodButtonBase,
    IconBankId,
  },
}
</script>

<template>
  <MethodButtonBase>
    <template v-slot:slot1>
      <IconBankId :class="$style.icon" />
    </template>
    <template v-slot:slot2>
      BankID
    </template>
  </MethodButtonBase>
</template>


<style lang="scss" module>
.icon {
  display: block;
  width: 30px;
  height: auto;
  margin-right: 10px;
  fill: #193E4F;
}
</style>
