<script>
import MethodButtonBase from './MethodButtonBase';
import BelgianEidIcon from '../../assets/svg/belgian-eid.svg';
export default {
  name: 'IdCardButtonBe',
  components: {
    MethodButtonBase,
    BelgianEidIcon,
  },
}
</script>

<template>
  <MethodButtonBase>
    <template v-slot:slot1>
      <BelgianEidIcon :class="$style.icon" />
    </template>
    <template v-slot:slot2>
      {{ $t('id-card') }}
    </template>
  </MethodButtonBase>
</template>

<style lang="scss" module>
.icon {
  display: block;
  width: 39px;
  height: auto;
  margin-right: 8px;
}
</style>
