<script>
import AppForm from '../components/AppForm';
import getFieldErrors from '../getFieldErrors';
import SubLoader from '../components/SubLoader';

export default {
  name: 'SignatureChaveMovel',
  components: {
    AppForm,
    SubLoader,
  },
  data() {
    return {
      fieldErrors: {},
      signingProcess: {},
      filesToBeSigned: [],
      step: null,
    }
  },
  computed: {
    inputValues() {
      return this.$root.$options.storeGetters.inputValues();
    },
    signatureIsProcessing() {
      return this.$root.$options.storeGetters.signatureIsProcessing();
    },
    additionalMethodConfig() {
      return this.$root.$options.storeGetters.additionalMethodConfig();
    },
    userCredentialsSchema() {
      return [
        {
          type: 'tel',
          name: 'phone',
          label: this.$t('phone-number') + ', ' + this.$t('including-calling-code'),
          validation: ['required'],
        },
        {
          type: 'password',
          name: 'signingPin',
          label: this.$t('pin'),
          validation: ['required'],
        },
        {
          type: 'textSection',
          text: this.$t('mobile-key-signing-info'),
        },
        {
          type: 'submit',
          label: this.$t('Sign')
        }
      ]
    },
    confirmationFormSchema() {
      return [
        {
          type: 'password',
          name: 'confirmationCode',
          label: this.$t('enter-temp-security-code'),
          validation: ['required'],
        },
        {
          type: 'textSection',
          text: this.$t('you-have-5-minutes-to-enter-the-code'),
        },
        {
          type: 'textSection',
          text: this.$t('after-confirmation-following-docs-will-be-signed') + ' '
              + this.filesToBeSigned.map(file => file.fileName).join(', ')
              + '. ' + this.$t('signature-confirmation-irreversible-note'),
        },
        {
          type: 'textSection',
          text: this.$t('mobile-key-signing-info'),
        },
        {
          type: 'submit',
          label: this.$t('confirm')
        }
      ]
    },
    userCredentialsFormValue: {
      get() {
        return {
          phone: this.inputValues.phone,
          signingPin: this.inputValues.signingPin,
        }
      },
      set(newValues) {
        this.updateInputValues(newValues);
      }
    },
    confirmationFormValue: {
      get() {
        return {
          confirmationCode: this.inputValues.confirmationCode,
        }
      },
      set(newValues) {
        this.updateInputValues(newValues);
      }
    },
  },
  created() {
    this.STEP_START = 'STEP_START';
    this.STEP_CONFIRM = 'STEP_CONFIRM';
    this.step = this.STEP_START;
  },
  methods: {
    updateInputValues(newValues) {
      this.$root.$options.storeActions.updateInputValues(newValues);
    },
    loadingStart() {
      this.$root.$options.storeActions.loadingStart();
    },
    loadingEnd() {
      this.$root.$options.storeActions.loadingEnd();
    },
    addFlashMessage(message) {
      this.$root.$options.storeActions.addFlashMessage(message);
    },
    clearFlashMessages() {
      this.$root.$options.storeActions.clearFlashMessages();
    },
    startSignatureProcessing() {
      this.$root.$options.storeActions.startSignatureProcessing();
    },
    endSignatureProcessing() {
      this.$root.$options.storeActions.endSignatureProcessing();
    },
    clearErrors() {
      this.fieldErrors = {};
    },
    cancel() {
      const {cancel} = this.signingProcess;
      if (cancel) {
        cancel();
      }
    },
    startSigning() {
      this.loadingStart();
      this.clearFlashMessages();
      this.clearErrors();

      const dataToSubmit = {
        ...this.userCredentialsFormValue,
      };
      const additionalMethodConfig = this.additionalMethodConfig || {};
      this.signingProcess = this.$root.$options.eidEasyClient.signature.chaveMovelSignature.start({
        ...dataToSubmit,
        started: (result) => {
          if (result.data && result.data.token) {
            this.loadingEnd();
            this.filesToBeSigned = result.data.files;
            this.step = this.STEP_CONFIRM;
          }
        },
        confirmationCodeRequest: (result, resolve) => {
          this.resolveConfirmation = resolve;
        },
        fail: (error) => {
          if (!error.isCancel) {
            this.addFlashMessage(error);
          }
          this.fieldErrors = getFieldErrors(error);
          this.$root.$options.eidEasyOnFail(error);
        },
        success: (result) => {
          this.$root.$options.eidEasyOnSuccess(result);
        },
        finished: () => {
          this.step = this.STEP_START;
          this.updateInputValues({
            confirmationCode: '',
          });
          this.endSignatureProcessing();
          this.loadingEnd();
        },
        ...additionalMethodConfig,
      });
    },
    confirm() {
      this.loadingStart();
      this.resolveConfirmation(this.confirmationFormValue.confirmationCode);
    },
  },
}
</script>

<template>
  <div :class="$style.formContainer">
    <div
      v-if="signatureIsProcessing"
      :class="$style.challenge"
    >
      <SubLoader />
    </div>
    <div v-else-if="step === STEP_START">
      <AppForm
        id="chaveMovelUserCredentialsForm"
        v-model="userCredentialsFormValue"
        :schema="userCredentialsSchema"
        :on-submit="startSigning"
        :errors="fieldErrors"
      />
    </div>
    <div v-else-if="step === STEP_CONFIRM">
      <AppForm
        id="chaveMovelConfirmationForm"
        v-model="confirmationFormValue"
        :schema="confirmationFormSchema"
        :on-submit="confirm"
        :errors="fieldErrors"
      />
    </div>
  </div>
</template>

<style lang="scss" module>
.formContainer {
  max-width: $form-container-width;
  margin: 0 auto;
}

.label {
  color: $gray-600;
  margin-top: $spacer-6;
}

.highlight {
  font-weight: $font-weight-bold;
  font-size: $font-size-xl;
}

</style>
