<script>
import AppFooter from './AppFooter';
import AppHeader from './AppHeader';
import LoadingOverlay from './LoadingOverlay';
import FlashMessages from './FlashMessages';
import HeaderContentCountry from './HeaderContentCountry';
import HeaderContentSubView from './HeaderContentSubView';
import views from '../views/views';

export default {
  name: 'AppLayout',
  components: {
    AppFooter,
    AppHeader,
    LoadingOverlay,
    FlashMessages,
    HeaderContentCountry,
    HeaderContentSubView,
    ...views,
  },
  computed: {
    currentView() {
      return this.$root.$options.storeGetters.currentView();
    },
    flashMessages() {
      return this.$root.$options.storeGetters.flashMessages();
    },
    isLoading() {
      return this.$root.$options.storeGetters.isLoading();
    }
  },
}
</script>

<template>
  <div :class="$style.layout">
    <AppHeader>
      <HeaderContentCountry v-if="currentView === 'MethodSelection'" />
      <HeaderContentSubView v-else />
    </AppHeader>
    <div :class="$style.main">
      <FlashMessages
        :messages="flashMessages"
      />
      <transition
        :name="$style.fade"
      >
        <LoadingOverlay v-show="isLoading" />
      </transition>
      <component :is="currentView" />
    </div>
    <AppFooter>
      <a
        href="https://eideasy.com"
        :class="$style.eidEasyLink"
        target="_blank"
      >
        {{ $t("powered-by-eideasy") }}
      </a>
    </AppFooter>
  </div>
</template>

<style lang="scss" module>
  .layout {
    background-color: $gray-100;
    border-radius: $border-radius;
    border: 1px solid $border-color;
  }

  .main {
    position: relative;
    padding: $spacer-6 $spacer-6;
  }

  .fade {
    &:global(-enter-active),
    &:global(-leave-active) {
      transition: opacity .2s;
    }
    &:global(-enter),
    &:global(-leave-to) {
      opacity: 0;
    }
  }

  .eidEasyLink {
    text-decoration: none;
    color: currentColor;
    transition: $transition-duration;

    &:hover,
    &:focus {
      text-decoration: none;
      color: $primary;
    }
  }
</style>
