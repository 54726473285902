// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".App_reset-list_Fl1IP{margin:0;padding:0;list-style:none;display:block}.App_uppercase-sm_293Iz{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.App_app_3cl75{font-family:Arial,sans-serif;font-size:16px;font-weight:400;line-height:1.4;min-width:260px}.App_app_3cl75 *{box-sizing:border-box}", ""]);
// Exports
exports.locals = {
	"reset-list": "App_reset-list_Fl1IP",
	"uppercase-sm": "App_uppercase-sm_293Iz",
	"app": "App_app_3cl75"
};
module.exports = exports;
