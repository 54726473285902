// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FormSectionSpacer_reset-list_x5brS{margin:0;padding:0;list-style:none;display:block}.FormSectionSpacer_uppercase-sm_3XM0F{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.FormSectionSpacer_spacer_BaryV{position:relative;text-align:center}.FormSectionSpacer_spacerText_1J1vC{display:inline-block;vertical-align:top;color:#3a3a3a}", ""]);
// Exports
exports.locals = {
	"reset-list": "FormSectionSpacer_reset-list_x5brS",
	"uppercase-sm": "FormSectionSpacer_uppercase-sm_3XM0F",
	"spacer": "FormSectionSpacer_spacer_BaryV",
	"spacerText": "FormSectionSpacer_spacerText_1J1vC"
};
module.exports = exports;
