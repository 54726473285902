<script>
import AppSpinner from './AppSpinner';

export default {
  name: 'SubLoader',
  components: {
    AppSpinner,
  },
  props: {
    challenge: String,
    userInstructions: String,
  }
}
</script>

<template>
  <div
    :class="$style.challenge"
  >
    <div :class="$style.loader">
      <AppSpinner scheme="secondary" />
    </div>
    <div :class="$style.content">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" module>
.challenge {
  text-align: center;
  color: $primary;
}

.loader {
  font-size: 14px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: $spacer-6;
}

.content {
  display: block;
}


</style>
