// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ZealIdButton_reset-list_3jp8B{margin:0;padding:0;list-style:none;display:block}.ZealIdButton_uppercase-sm_XDmsQ{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.ZealIdButton_icon_1kwll{display:block;width:80px;height:auto}", ""]);
// Exports
exports.locals = {
	"reset-list": "ZealIdButton_reset-list_3jp8B",
	"uppercase-sm": "ZealIdButton_uppercase-sm_XDmsQ",
	"icon": "ZealIdButton_icon_1kwll"
};
module.exports = exports;
