// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".IdentificationZealId_reset-list_3tNtq{margin:0;padding:0;list-style:none;display:block}.IdentificationZealId_uppercase-sm_1KiwP{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.IdentificationZealId_formContainer_2pghK{width:100%}.IdentificationZealId_iframeHolder_hA0Df iframe{width:100%;height:1200px}.IdentificationZealId_hidden_2IvPU{display:none}.IdentificationZealId_buttonHolder_3AMm3{max-width:263px;margin:0 auto}", ""]);
// Exports
exports.locals = {
	"reset-list": "IdentificationZealId_reset-list_3tNtq",
	"uppercase-sm": "IdentificationZealId_uppercase-sm_1KiwP",
	"formContainer": "IdentificationZealId_formContainer_2pghK",
	"iframeHolder": "IdentificationZealId_iframeHolder_hA0Df",
	"hidden": "IdentificationZealId_hidden_2IvPU",
	"buttonHolder": "IdentificationZealId_buttonHolder_3AMm3"
};
module.exports = exports;
