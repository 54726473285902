// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CountrySelectOption_reset-list_1RrxV{margin:0;padding:0;list-style:none;display:block}.CountrySelectOption_uppercase-sm_3hhtW{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.CountrySelectOption_option_3Hm7z{display:flex;width:100%}.CountrySelectOption_optionText_1iN4T{font-size:16px;color:#292a2b;letter-spacing:.009375em;margin-left:16px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.CountrySelectOption_option_3Hm7z svg{display:block;width:26px;height:22px}", ""]);
// Exports
exports.locals = {
	"reset-list": "CountrySelectOption_reset-list_1RrxV",
	"uppercase-sm": "CountrySelectOption_uppercase-sm_3hhtW",
	"option": "CountrySelectOption_option_3Hm7z",
	"optionText": "CountrySelectOption_optionText_1iN4T"
};
module.exports = exports;
