// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".MojeIdButton_reset-list_3YL8g{margin:0;padding:0;list-style:none;display:block}.MojeIdButton_uppercase-sm_2n8e-{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.MojeIdButton_base_3NWcs{background:linear-gradient(180deg,#565656,#151515)}.MojeIdButton_icon_3M-Wu{display:block;width:80px;height:auto}", ""]);
// Exports
exports.locals = {
	"reset-list": "MojeIdButton_reset-list_3YL8g",
	"uppercase-sm": "MojeIdButton_uppercase-sm_2n8e-",
	"base": "MojeIdButton_base_3NWcs",
	"icon": "MojeIdButton_icon_3M-Wu"
};
module.exports = exports;
