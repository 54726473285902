<script>
export default {
  name: 'FormText',
  props: {
    text: String,
  },
}
</script>

<template>
  <div :class="$style.textSection">
    <div :class="$style.textSectionText">
      {{ text }}
    </div>
  </div>
</template>

<style lang="scss" module>
.textSection {
  position: relative;
  text-align: center;
}

.textSectionText {
  display: inline-block;
  vertical-align: top;
  color: $gray-700;
  font-size: $font-size-md;
}
</style>
