<script>
import AppForm from '../components/AppForm';
import getFieldErrors from '../getFieldErrors';
import AppAlert from '../components/AppAlert';
import AppButton from '../components/AppButton';
import ConfirmationStep from '../components/ConfirmationStep';

export default {
  name: 'SignatureOtp',
  components: {
    AppForm,
    AppAlert,
    AppButton,
    ConfirmationStep,
  },
  data() {
    return {
      fieldErrors: {},
      challenge: undefined,
      authProcess: {},
      step: null,
      prevStep: null,
    }
  },
  computed: {
    signatureIsProcessing() {
      return this.$root.$options.storeGetters.signatureIsProcessing();
    },
    inputValues() {
      return this.$root.$options.storeGetters.inputValues();
    },
    otpEmail() {
      return this.$root.$options.storeGetters.otpEmail();
    },
    otpPhone() {
      return this.$root.$options.storeGetters.otpPhone();
    },
    emailToken() {
      return this.$root.$options.storeGetters.emailToken();
    },
    isOtpSent() {
      return this.$root.$options.storeGetters.isOtpSent();
    },
    additionalMethodConfig() {
      return this.$root.$options.storeGetters.additionalMethodConfig();
    },
    requestCodeSchema() {
      const codeRecipients = [];
      if (this.otpPhone) {
        codeRecipients.push(this.otpPhone);
      }
      if (this.otpEmail) {
        codeRecipients.push(this.otpEmail);
      }
      return [
        {
          type: 'textSection',
          text: this.$t('confirmation-code-will-be-sent-to') + ' ' + codeRecipients.join(' & '),
        },
        {
          type: 'submit',
          label: this.$t('continue')
        }
      ]
    },
    confirmationFormSchema() {
      const schema = [];
      if (this.otpEmail) {
        schema.push({
          type: this.emailToken ? 'hidden' : 'text',
          name: 'email_token',
          instructions: this.$t('email-code-sent') + ' ' + this.otpEmail,
          label: this.$t('your-code'),
          validation: ['required'],
        });
      }
      if (this.otpPhone) {
        schema.push({
          type: 'text',
          name: 'sms_token',
          instructions: this.$t('sms-code-sent') + ' ' + this.otpPhone,
          label: this.$t('your-code'),
          validation: ['required'],
        });
      }

      if (schema.length) {
        schema.push({
          type: 'submit',
          label: this.$t('Sign')
        });
      }
      return schema;
    },
    formValue: {
      get() {
        return {
          email_token: this.inputValues.email_token,
          sms_token: this.inputValues.sms_token,
        }
      },
      set(newValues) {
        this.updateInputValues(newValues);
      }
    },
  },
  beforeDestroy() {
    this.cancel();
  },
  created() {
    this.STEP_REQUEST_CODE = 'STEP_REQUEST_CODE';
    this.STEP_ENTER_CODE = 'STEP_ENTER_CODE';
    this.STEP_CONFIRM = 'STEP_CONFIRM';

    this.goToStartingStep();
  },
  methods: {
    getStartingStep() {
      if (this.isOtpSent) {
        return this.STEP_ENTER_CODE;
      } else {
        return this.STEP_REQUEST_CODE;
      }
    },
    goToStartingStep() {
      this.goToStep(this.getStartingStep());
    },
    goToStep(newStep) {
      this.prevStep = this.step;
      this.step = newStep;
    },
    stepBack() {
      this.goToStep(this.prevStep);
    },
    updateInputValues(newValues) {
      this.$root.$options.storeActions.updateInputValues(newValues);
    },
    startSignatureProcessing() {
      this.$root.$options.storeActions.startSignatureProcessing();
    },
    endSignatureProcessing() {
      this.$root.$options.storeActions.endSignatureProcessing();
    },
    loadingStart() {
      this.$root.$options.storeActions.loadingStart();
    },
    loadingEnd() {
      this.$root.$options.storeActions.loadingEnd();
    },
    addFlashMessage(message) {
      this.$root.$options.storeActions.addFlashMessage(message);
    },
    clearFlashMessages() {
      this.$root.$options.storeActions.clearFlashMessages();
    },
    clearErrors() {
      this.fieldErrors = {};
    },
    cancel() {
      const {cancel} = this.authProcess;
      if (cancel) {
        cancel();
      }
    },
    startConfirm() {
      this.goToStep(this.STEP_CONFIRM);
    },
    rejectConfirm() {
      this.stepBack();
    },
    approveConfirm() {
      this.startSigning();
    },
    startSigning() {
      this.loadingStart();
      this.clearFlashMessages();
      this.clearErrors();
      const additionalMethodConfig = this.additionalMethodConfig || {};
      this.authProcess = this.$root.$options.eidEasyClient.signature.otpSignature.start({
        emailToken: this.formValue.email_token,
        smsToken: this.formValue.sms_token,
        started: (result) => {
          if (result.data && result.data.token) {
            this.loadingEnd();
            this.goToStep(this.STEP_ENTER_CODE);
          }
        },
        confirmationCodeRequest: (result, resolve) => {
          this.resolveConfirmation = resolve;
        },
        fail: (error) => {
          if (!error.isCancel) {
            this.addFlashMessage(error);
            this.updateInputValues({
              sms_token: '',
              email_token: '',
            });
          }
          this.fieldErrors = getFieldErrors(error);
          this.$root.$options.eidEasyOnFail(error);
        },
        success: (result) => {
          this.$root.$options.eidEasyOnSuccess(result);
        },
        finished: () => {
          this.goToStartingStep();
          this.endSignatureProcessing();
          this.loadingEnd();
        },
        ...additionalMethodConfig,
      });
    }
  },
}
</script>

<template>
  <div :class="$style.formContainer">
    <div v-if="!otpPhone && !otpEmail">
      <AppAlert scheme="danger">
        Missing otpEmail or otpPhone
      </AppAlert>
    </div>
    <ConfirmationStep
      v-else-if="step === STEP_CONFIRM"
      :on-approve-confirm="approveConfirm"
      :on-reject-confirm="rejectConfirm"
    />
    <div v-else-if="step === STEP_REQUEST_CODE">
      <AppForm
        id="requestCodeForm"
        :schema="requestCodeSchema"
        :on-submit="startSigning"
        :errors="fieldErrors"
      />
    </div>
    <div v-else>
      <div v-if="emailToken">
        <div
          v-if="otpPhone"
          :class="$style.mb4"
        >
          Phone: {{ otpPhone }}
        </div>
        <div
          v-if="otpEmail"
          :class="$style.mb4"
        >
          Email: <b>{{ otpEmail }}</b>
        </div>
      </div>
      <div v-if="confirmationFormSchema.length">
        <AppForm
          id="otpSignatureForm"
          v-model="formValue"
          :schema="confirmationFormSchema"
          :on-submit="startConfirm"
          :errors="fieldErrors"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.formContainer {
  max-width: $form-container-width;
  margin: 0 auto;
}

.line {
  margin-bottom: $spacer-3;
}

.mb4 {
  margin-bottom: $spacer-4;
}
</style>
