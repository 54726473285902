import IdCardButton from './IdCardButton';
import IdCardButtonEE from './IdCardButtonEE';
import SmartIdButton from './SmartIdButton';
import MobileIdButton from './MobileIdButton';
import MobileIdButtonEE from './MobileIdButtonEE';
import EparakstsMobileButton from './EparakstsMobileButton';
import FrejaEidButton from './FrejaEidButton';
import ZealIdButton from './ZealIdButton';
import IdCardButtonBe from './IdCardButtonBe';
import MojeIdButton from './MojeIdButton';
import FinnishTrustNetworkButton from './FinnishTrustNetworkButton';
import AtHandyButton from './AtHandyButton';
import IdCardButtonFi from './IdCardButtonFi';
import IdCardButtonLv from './IdCardButtonLv';
import IdCardButtonPt from './IdCardButtonPt';
import OtpButton from './OtpButton';
import GoogleButton from './GoogleButton';
import EvrotrustButton from './EvrotrustButton';
import BankIdButton from './BankIdButton';
import DTrustSignMeButton from './DTrustSignMeButton';
import MitIdButton from './MitIdButton';
import ChaveMovelButton from './ChaveMovelButton';
import NoBankIdButton from './NoBankIdButton';
import ItsmeButton from './ItsmeButton';
import CertEuropeUsbButton from './CertEuropeUsbButton';
import CertSignUsbButton from './CertSignUsbButton';

const methodButtons = Object.freeze({
  IdCardButton,
  IdCardButtonEE,
  SmartIdButton,
  MobileIdButton,
  MobileIdButtonEE,
  EparakstsMobileButton,
  FrejaEidButton,
  ZealIdButton,
  IdCardButtonBe,
  MojeIdButton,
  FinnishTrustNetworkButton,
  AtHandyButton,
  IdCardButtonFi,
  IdCardButtonLv,
  IdCardButtonPt,
  OtpButton,
  GoogleButton,
  EvrotrustButton,
  BankIdButton,
  DTrustSignMeButton,
  MitIdButton,
  ChaveMovelButton,
  NoBankIdButton,
  ItsmeButton,
  CertEuropeUsbButton,
  CertSignUsbButton,
});

export default methodButtons;
