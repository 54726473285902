<script>
import AppButton from './AppButton';

export default {
  name: 'ConfirmationStep',
  components: {
    AppButton,
  },
  props: {
    onApproveConfirm: {
      type: Function,
      default: () => {},
    },
    onRejectConfirm: {
      type: Function,
      default: () => {},
    }
  },
}
</script>

<template>
  <div>
    <div :class="$style.mb4">
      {{ $t('confirm-doc-check-and-sign') }}
    </div>
    <AppButton
      :class="$style.mb4"
      :on-click="onApproveConfirm"
    >
      {{ $t('yes') }}
    </AppButton>
    <AppButton
      scheme="secondary"
      :class="$style.mb4"
      :on-click="onRejectConfirm"
    >
      {{ $t('no') }}
    </AppButton>
  </div>
</template>

<style lang="scss" module>
.mb4 {
  margin-bottom: $spacer-4;
}
</style>
