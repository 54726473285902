<script>
import AppAlert from './AppAlert';

export default {
  name: 'FlashMessages',
  components: {
    AppAlert,
  },
  props: {
    messages: Array,
  },
  computed: {
    flashMessages() {
      const $t = this.$t.bind(this);
      const eidEasyFormatFlashMessage = this.$root.$options.eidEasyFormatFlashMessage;
      return this.messages.map(function(message) {
        if (message.translationKey) {
          message.text = $t(message.translationKey);
        }

        if (typeof eidEasyFormatFlashMessage === 'function') {
          return eidEasyFormatFlashMessage(message);
        } else {
          return message;
        }
      });
    },
  }
}
</script>

<template>
  <div>
    <AppAlert
      v-for="message in flashMessages"
      :key="message.text"

      :scheme="message.scheme"
    >
      {{ message.text }}
    </AppAlert>
  </div>
</template>

