<script>
import AppButton from '../components/AppButton';

export default {
  name: 'IdentificationZealId',
  components: {
    AppButton,
  },
  data() {
    return {
      identificationProcess: {},
      error: null,
    }
  },
  computed: {
    additionalMethodConfig() {
      return this.$root.$options.storeGetters.additionalMethodConfig();
    },
  },
  mounted() {
    this.$nextTick(function () {
      this.startIdentification();
    })
  },
  beforeDestroy() {
    this.cancel();
  },
  methods: {
    loadingStart() {
      this.$root.$options.storeActions.loadingStart();
    },
    loadingEnd() {
      this.$root.$options.storeActions.loadingEnd();
    },
    addFlashMessage(message) {
      this.$root.$options.storeActions.addFlashMessage(message);
    },
    clearFlashMessages() {
      this.$root.$options.storeActions.clearFlashMessages();
    },
    cancel() {
      const {cancel} = this.identificationProcess;
      if (cancel) {
        cancel();
      }
    },
    startIdentification() {
      this.error = null;
      this.clearFlashMessages();
      const additionalMethodConfig = this.additionalMethodConfig || {};
      this.identificationProcess = this.$root.$options.eidEasyClient.identification.zealId.start({
        iframeHolder: this.$refs.iframeHolder,
        fail: (error) => {
          if (!error.isCancel) {
            this.addFlashMessage(error);
            // remove the failed iframe
            this.$refs.iframeHolder.innerHTML = '';
          }
          this.error = error;
          this.$root.$options.eidEasyOnFail(error);
        },
        success: (result) => {
          this.$root.$options.eidEasyOnSuccess(result);
        },
        finished: () => {
          this.loadingEnd();
        },
        ...additionalMethodConfig,
      });
    }
  },
}
</script>

<template>
  <div :class="$style.formContainer">
    <div
      v-if="error"
      :class="$style.buttonHolder"
    >
      <AppButton
        :on-click="() => startIdentification()"
      >
        {{ $t('try-again') }}
      </AppButton>
    </div>
    <div
      ref="iframeHolder"
      :class="[$style.iframeHolder, {[$style.hidden]: error}]"
    />
  </div>
</template>

<style lang="scss" module>
.formContainer {
  width: 100%;
}

.iframeHolder iframe {
  width: 100%;
  height: 1200px;
}

.hidden {
  display: none;
}

.buttonHolder {
  max-width: $form-container-width;
  margin: 0 auto;
}
</style>
