// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".MobileIdButtonEE_reset-list_1k6l8{margin:0;padding:0;list-style:none;display:block}.MobileIdButtonEE_uppercase-sm_3Xnaa{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.MobileIdButtonEE_icon_j8s8z{display:block;width:112px;height:auto}", ""]);
// Exports
exports.locals = {
	"reset-list": "MobileIdButtonEE_reset-list_1k6l8",
	"uppercase-sm": "MobileIdButtonEE_uppercase-sm_3Xnaa",
	"icon": "MobileIdButtonEE_icon_j8s8z"
};
module.exports = exports;
