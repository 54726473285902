// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AppSpinner_reset-list_3OOqe{margin:0;padding:0;list-style:none;display:block}.AppSpinner_uppercase-sm_3vDWX{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.AppSpinner_spinner_299VM{color:#003168;font-size:1em;width:4.375em;height:4.375em;border:.3125em solid currentColor;border-top-color:transparent;border-radius:50%;-webkit-animation:AppSpinner_spin_NpaBY 1s linear infinite;animation:AppSpinner_spin_NpaBY 1s linear infinite;margin-left:auto;margin-right:auto}.AppSpinner_secondary_1oGWR{color:#34a853}@-webkit-keyframes AppSpinner_spin_NpaBY{to{transform:rotate(1turn)}}@keyframes AppSpinner_spin_NpaBY{to{transform:rotate(1turn)}}", ""]);
// Exports
exports.locals = {
	"reset-list": "AppSpinner_reset-list_3OOqe",
	"uppercase-sm": "AppSpinner_uppercase-sm_3vDWX",
	"spinner": "AppSpinner_spinner_299VM",
	"spin": "AppSpinner_spin_NpaBY",
	"secondary": "AppSpinner_secondary_1oGWR"
};
module.exports = exports;
