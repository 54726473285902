<script>
import MethodButtonBase from './MethodButtonBase';
export default {
  name: 'CertEuropeUsbButton',
  components: {
    MethodButtonBase,
  },
}
</script>

<template>
  <MethodButtonBase>
    <template v-slot:slot1>
      {{ $t('CertEurope USB') }}
    </template>
  </MethodButtonBase>
</template>
