<script>
import MethodButtonBase from './MethodButtonBase';
import ItsmeIcon from '../../assets/svg/itsme.svg';
export default {
  name: 'ItsmeButton',
  components: {
    MethodButtonBase,
    ItsmeIcon,
  },
}
</script>

<template>
  <MethodButtonBase>
    <template v-slot:slot1>
      <ItsmeIcon :class="$style.icon" />
    </template>
    <template v-slot:slot2>
      {{ $t('itsme') }}
    </template>
  </MethodButtonBase>
</template>

<style lang="scss" module>
.icon {
  display: block;
  width: 29px;
  height: auto;
  margin-right: 8px;
}
</style>
