// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DTrustSignMeButton_reset-list_27Wbu{margin:0;padding:0;list-style:none;display:block}.DTrustSignMeButton_uppercase-sm_2t3ap{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.DTrustSignMeButton_icon_3qU47{display:block;width:102px;height:auto;margin-top:-5px}", ""]);
// Exports
exports.locals = {
	"reset-list": "DTrustSignMeButton_reset-list_27Wbu",
	"uppercase-sm": "DTrustSignMeButton_uppercase-sm_2t3ap",
	"icon": "DTrustSignMeButton_icon_3qU47"
};
module.exports = exports;
