// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SubLoader_reset-list_Lna9G{margin:0;padding:0;list-style:none;display:block}.SubLoader_uppercase-sm_5llfl{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.SubLoader_challenge_2IzAN{text-align:center;color:#003168}.SubLoader_loader_33Op9{font-size:14px;margin-left:auto;margin-right:auto;margin-bottom:24px}.SubLoader_content_3aRX5,.SubLoader_loader_33Op9{display:block}", ""]);
// Exports
exports.locals = {
	"reset-list": "SubLoader_reset-list_Lna9G",
	"uppercase-sm": "SubLoader_uppercase-sm_5llfl",
	"challenge": "SubLoader_challenge_2IzAN",
	"loader": "SubLoader_loader_33Op9",
	"content": "SubLoader_content_3aRX5"
};
module.exports = exports;
