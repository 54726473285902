// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SignatureFtnQes_reset-list_3EUzS{margin:0;padding:0;list-style:none;display:block}.SignatureFtnQes_uppercase-sm_emYW4{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.SignatureFtnQes_formContainer_pquiY{max-width:263px;margin:0 auto}.SignatureFtnQes_label_Stdbc{color:#9ca6b1;margin-top:24px}.SignatureFtnQes_highlight_13lr_{font-weight:700;font-size:20px}", ""]);
// Exports
exports.locals = {
	"reset-list": "SignatureFtnQes_reset-list_3EUzS",
	"uppercase-sm": "SignatureFtnQes_uppercase-sm_emYW4",
	"formContainer": "SignatureFtnQes_formContainer_pquiY",
	"label": "SignatureFtnQes_label_Stdbc",
	"highlight": "SignatureFtnQes_highlight_13lr_"
};
module.exports = exports;
