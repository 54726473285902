<script>
import MethodButtonBase from './MethodButtonBase';
import IconMojeId from '../../assets/svg/mojeid.svg';

export default {
  name: 'MojeIdButton',
  components: {
    MethodButtonBase,
    IconMojeId,
  },
}
</script>

<template>
  <MethodButtonBase :class="$style.base">
    <template v-slot:slot1>
      <IconMojeId :class="$style.icon" />
    </template>
  </MethodButtonBase>
</template>

<style lang="scss" module>

.base {
  background: linear-gradient(180deg, rgba(86,86,86,1) 0%, rgba(21,21,21,1) 100%);
}
.icon {
  display: block;
  width: 80px;
  height: auto;
}
</style>
