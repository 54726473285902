// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".EparakstsMobileButton_reset-list_15Y1G{margin:0;padding:0;list-style:none;display:block}.EparakstsMobileButton_uppercase-sm_2q7Sp{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.EparakstsMobileButton_icon_1gCDF{display:block;width:28px;height:auto;margin-right:8px}", ""]);
// Exports
exports.locals = {
	"reset-list": "EparakstsMobileButton_reset-list_15Y1G",
	"uppercase-sm": "EparakstsMobileButton_uppercase-sm_2q7Sp",
	"icon": "EparakstsMobileButton_icon_1gCDF"
};
module.exports = exports;
