// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LoadingOverlay_reset-list_2PGxt{margin:0;padding:0;list-style:none;display:block}.LoadingOverlay_uppercase-sm_32MBC{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.LoadingOverlay_loadingOverlay_QjyT6{position:absolute;width:100%;height:100%;left:0;top:0;background-color:hsla(0,0%,100%,.5);-webkit-backdrop-filter:blur(3px);backdrop-filter:blur(3px);z-index:900}.LoadingOverlay_spinnerHolder_2ZD4H{position:absolute;left:50%;top:50%;transform:translate3d(-50%,-50%,0);pointer-events:none;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}", ""]);
// Exports
exports.locals = {
	"reset-list": "LoadingOverlay_reset-list_2PGxt",
	"uppercase-sm": "LoadingOverlay_uppercase-sm_32MBC",
	"loadingOverlay": "LoadingOverlay_loadingOverlay_QjyT6",
	"spinnerHolder": "LoadingOverlay_spinnerHolder_2ZD4H"
};
module.exports = exports;
