// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SignatureMobileId_reset-list_E679l{margin:0;padding:0;list-style:none;display:block}.SignatureMobileId_uppercase-sm_3xJL0{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.SignatureMobileId_formContainer_EBKOF{max-width:263px;margin:0 auto}", ""]);
// Exports
exports.locals = {
	"reset-list": "SignatureMobileId_reset-list_E679l",
	"uppercase-sm": "SignatureMobileId_uppercase-sm_3xJL0",
	"formContainer": "SignatureMobileId_formContainer_EBKOF"
};
module.exports = exports;
