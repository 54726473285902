<script>
import MethodButtonBase from './MethodButtonBase';
import IconZealId from '../../assets/svg/zealid.svg';
export default {
  name: 'ZealIdButton',
  components: {
    MethodButtonBase,
    IconZealId,
  },
}
</script>

<template>
  <MethodButtonBase>
    <template v-slot:slot1>
      <IconZealId :class="$style.icon" />
    </template>
  </MethodButtonBase>
</template>


<style lang="scss" module>
.icon {
  display: block;
  width: 80px;
  height: auto;
}
</style>
