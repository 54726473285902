// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".IdCardButtonBe_reset-list_1F-Dq{margin:0;padding:0;list-style:none;display:block}.IdCardButtonBe_uppercase-sm_3olZ3{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.IdCardButtonBe_icon_3FST8{display:block;width:39px;height:auto;margin-right:8px}", ""]);
// Exports
exports.locals = {
	"reset-list": "IdCardButtonBe_reset-list_1F-Dq",
	"uppercase-sm": "IdCardButtonBe_uppercase-sm_3olZ3",
	"icon": "IdCardButtonBe_icon_3FST8"
};
module.exports = exports;
